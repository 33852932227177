<template>
  <div class="list-item" @click="handleClick">
    <el-card>
      <el-row :gutter="40">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <div class="list-item-photo">
            <el-image class="image" style="width:100%; height:160px" fit="fill" :src="pics" />
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <div class="list-item-content">
            <h3>{{title}}</h3>
            <p>{{source}} {{datetime}}</p>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>


<script>
export default {
  name: 'NewsItem',
  props: {
    rowid: {
      required: true,
      type: Number
    },
    itemData: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      idx: this.rowid,
      newsid: this.itemData.newsid,
      pics: this.itemData.pics,
      title: this.itemData.title,
      source: this.itemData.source,
      datetime: this.itemData.datetime,
    }
  },
  methods: {
    handleClick() {
      this.$emit('on-click', this.idx);
    }
  }
}
</script>


<style lang="scss" scoped>
.list-item {
  padding: 10px 10%;
  cursor: pointer;
}
.list-item-photo {
  text-align: center;
}
.list-item-content {
  text-align: left;
  font-size: 1.2em;
}
</style>